import { Outlet } from "react-router-dom";
import BANNER from "../assets/auth_bg.png";
function AuthLayout() {
  return (
    <div className="w-full h-screen bg-white overflow-y-auto style-scroll">
      <section className="grid h-full grid-cols-1 md:grid-cols-2 place-content-center place-items-center">
        <div className="px-4 lg:px-8 pt-28 container mx-auto  h-screen overflow-auto flex items-center justify-center">
          <Outlet />
        </div>
        <div className="hidden md:flex items-center justify-center h-screen w-full">
          <img src={BANNER} alt="" className="object-cover h-screen w-full" />
        </div>
      </section>
    </div>
  );
}

export default AuthLayout;
