import React from "react";
import { NavLink } from "react-router-dom";

interface NavigationLinkProps {
  to: string;

  children: React.ReactNode;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  to,

  children,
}) => {
  return (
    <NavLink
      end
      to={to}
      className={({ isActive }) =>
        isActive
          ? "btn-primary-gradient"
          : "text-cmathsDark hover:text-cmathsPrimary hover:bg-primary-gradient hover:text-transparent hover:bg-clip-text transition-all duration-300 ease-in-out"
      }
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
